@import "../../../styles/base.scss";

.odd_selector {
  margin: 0 px-to-vw(15, 390);
  h2 {
    font-size: px-to-vw(16, 390);
    line-height: px-to-vw(18, 390);
    font-weight: 700;
    color: $white;
    margin:  0 0 px-to-vw(15, 390) 0;
      text-transform: capitalize;
    }  

  .odd_options {
    display: flex;
    gap: px-to-vw(15, 390);

    &.invert_order {
      flex-direction: row-reverse;
    }

    .odd_option {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: px-to-vw(8, 390);

      .logo {
        padding: px-to-vw(6, 390);
        border: none;
        width: 100%;
        height: px-to-vw(40, 390);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: px-to-vw(5, 390);
        background-color: $background-secondary;
        border: px-to-vw(1, 390) solid $border-color;
        img {
          width: auto;
          height: 100%;
          object-fit: contain;
        }

        p {
          color: $white;
          font-weight: 500;
          font-size: px-to-vw(14, 390);
          line-height: normal;
          margin: 0;
        }

        &.active {
          background-color: $primary;

          p {
            color: $white;
          }
        }
      }

      .value {
        font-size: px-to-vw(10, 390);
        line-height: px-to-vw(16, 390);
        font-weight: 300;
        color: $white;
        margin: 0;
      }
    }
  }
}
.odd_selector:not(:first-child) {
  .odd_options {
    gap: px-to-vw(10, 390);
  }
}
