@import "./styles/base.scss";


* {
    box-sizing: border-box;
  }
  
  body {
    font-family: $rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    color: $white;
    overflow-x: hidden;
    background: $background;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    color: inherit;
  }
  
  strong {
    font-weight: bold;
  }
  
  em {
    font-style: italic;
  }
  
  * {
    -webkit-tap-highlight-color: transparent;
  }
  
  input {
    &::placeholder {
      color: rgba($white, 0.8);
    }
  }
  
  .no-results {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: px-to-vw(15, 390);
    padding: px-to-vw(12, 390) 0;
  
    .text {
      text-align: center;
      h2 {
        color: $primary;
        font-size: px-to-vw(24, 390);
        text-transform: initial;
        margin: 0;
      }
      p {
        color: $white;
        line-height: px-to-vw(22, 390);
        margin-top: 10px;
        text-transform: initial;
      }
    }
  }