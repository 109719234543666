@import "../../styles/base.scss";

.banner {
    text-align: center;
    background-color: $primary;
    padding: 0;
    // width: px-to-vw(393, 390);
    // height: px-to-vw(52, 390);
    display: flex;
    align-items: center;
    justify-content: center;

    .appTitle{
        font-size: px-to-vw(20, 390);
        letter-spacing: 0px;
        font-weight: 600;
        margin: 0;
        color: $white;
    }

    .appTitleContainer {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    
      .sponsorBanner {
        width: 100%;
        height: auto;
        margin: 0;
      }
    
}

nav{
    margin: px-to-vw(15, 390) 0;
    padding: 0 px-to-vw(12, 390);
}

.container{
    padding: 0 px-to-vw(12, 390);
}
