@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.navigation {
  display: flex;
  width: 100%;
  gap: px-to-vw(8, 390);   
  margin: 0;
  padding: 0;
  height: 120px;

  .navigation_item {
    // aspect-ratio: 1/1;
    background-color: transparent;
    padding: px-to-vw(4, 390);
    border: 1px solid $primary;
    border-radius: px-to-vw(5, 390);
    list-style-type: none;
    width: 100%;

    a{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: px-to-vw(16, 390);
      width: 100%;
      height: 100%;
      
      .image_container {
        width: px-to-vw(24, 390);
        height: px-to-vw(24, 390);
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: px-to-vw(24, 390);
          height: px-to-vw(24, 390);
        }

        div {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      p {
        font-size: px-to-vw(9, 390);
        line-height: px-to-vw(16, 390);
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        color: $white;
        margin: 0;
      }
    }



    &.active {
      background-color: $primary;
      border-color: $border-color;
      .image_container {
        border: none;
      }

      p {
        color: $white;
      }
    }


  }
}
