@import "../../../styles/base.scss";

.odd_selector {
  color: $white;

  margin: 0 px-to-vw(15, 390);

  h2 {
    font-size: px-to-vw(16, 390);
    line-height: px-to-vw(18, 390);
    font-weight: 700;
    color: $white;
    margin:  0 0 px-to-vw(9, 390) 0;
      text-transform: capitalize;
    }  

  :global(.select_container) {
    border: 1px solid $border-color !important;

    li {
      color: $black !important;
    }
  }

  p.value {
    color: $white;
    font-family: $rubik;
    font-size: px-to-vw(10, 390);
    font-style: normal;
    font-weight: 400;
    line-height: px-to-vw(16, 390);
    width: max-content;
    margin: px-to-vw(9, 390) auto 0;
  }

  .selectButton {
    background-color: $background-secondary;
    color: $white;
    font-family: $rubik;
    font-size: px-to-vw(14, 390);
    font-weight: 500;
    line-height: normal;
    width: 100%;
    padding: px-to-vw(4, 390) px-to-vw(8, 390);
    height: px-to-vw(40, 390);
    border-radius: px-to-vw(5, 390);
    border: px-to-vw(1, 390) solid $border-color;
  }
  .selectedPlayer {
    font-weight: 500;
    color: $white;
    background-color: $primary;
    font-size: px-to-vw(14, 390);
    line-height: px-to-vw(14, 390);
    padding: px-to-vw(4, 390) px-to-vw(8, 390);
    height: px-to-vw(40, 390);
    border-radius: px-to-vw(5, 390);
    border: px-to-vw(1, 390) solid $primary; 
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: px-to-vw(1, 390) solid $border-color;
  }
}
