@import "../../styles/base.scss";

.matches { 
  padding: 0;
  margin: 0;

  .bannerContainer {
    display: block;
    width: 100%;
    height: auto;

    .sponsorBanner {
      width: 100%;
    }
  }

  .matchesList {
    padding: 0;
    margin: 0;
    list-style-type: none;
    .matchCard {
      margin: px-to-vw(15, 390) 0;
      background: $background-secondary;
      border: 1px solid $border-color;
      border-radius: px-to-vw(5, 390);
    }
  }

  .filters {
    background-color: $white;
    display: flex;
    position: relative;

    .filter {
      flex: 1;
      text-align: center;
      font-family: $rubik;
      font-size: px-to-vw(12, 390);
      font-style: normal;
      font-weight: 500;
      line-height: px-to-vw(16, 390);
      padding: px-to-vw(15, 390) 0;
      color: $border-color;

      &.active {
        color: $primary;
      }
    }

    .active_bar {
      width: 50%;
      height: px-to-vw(4, 390);
      background-color: $primary;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.5s;

      &.right {
        transform: translateX(100%);
      }
    }
  }
}
