@import "../../styles/base.scss";

.pop_up_prediction_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;

  .pop_up_container__inner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow-y: scroll;
    background: $background;

    padding: px-to-vw(46, 390) px-to-vw(13, 390);

    .total_odds_container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: px-to-vw(20, 390);
      gap: px-to-vw(5, 390);
    }
    
    .total_odds_label {
      font-family: $roboto;
      margin-right: px-to-vw(5, 390);
      text-transform: capitalize;
      font-weight: 400;
      font-size: px-to-vw(12, 390);
      line-height: px-to-vw(16, 390);
      margin: 0;
    }
    
    .total_odds_value {
      font-family: $roboto;
      font-size: px-to-vw(20, 390);
      font-weight: 800;
      color: $secondary;
      line-height: px-to-vw(15, 390);
      margin: 0;
    }

    .btn_close__container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: px-to-vw(20, 390);


      .btn_close {
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
        font-size: 0;

        img {
          width: px-to-vw(14, 390);
          height: auto;
        }
      }
    }

    .send {
      background-color: $primary;
      color: $black;
      font-family: $rubik;
      font-size: px-to-vw(15, 390);
      font-weight: 800;
      line-height: normal;
      width: 100%;
      margin: 0 auto;
      padding: px-to-vw(8, 390) px-to-vw(16, 390);
      border-radius: px-to-vw(4, 390);
    }

    .no_points {
      display: block;
      text-align: center;
      font-size: 2.791vw;
      font-weight: 400;
      line-height: 4.186vw;
      color: $white;
      margin-top: 1.86vw;
      font-weight: 600;
    }

    .loader {
      @include absolute-center();
      top: 56%;
    }

    .odds_container {
      display: flex;
      flex-direction: column;
      gap: px-to-vw(20, 390);
      margin-bottom: px-to-vw(20, 390);
    }

    .error {
      color: $primary;
      font-size: px-to-vw(14, 390);
      font-weight: 700;
      margin: px-to-vw(15, 390) auto;
      text-align: center;
    }

    .total_sum {
      font-family: $rubik;
      font-size: px-to-vw(12, 390);
      font-weight: 400;
      line-height: px-to-vw(16, 390);
      margin: px-to-vw(8, 390) auto;
      display: none;

      span {
        color: $primary;
        font-weight: 800;
        margin-left: px-to-vw(10, 390);
      }
    }

    .no_odds {
      margin: 20px auto;
      text-align: center;
      font-size: px-to-vw(14, 390);
      line-height: px-to-vw(18, 390);
      color: rgba($white, 0.5);
    }
    .prediction_button_container {
      margin: 0 px-to-vw(15, 390);
    }
  }
}
