@import "../../styles/base.scss";

.matchCardContainer {
  display: flex;
  flex-direction: column;
  padding: px-to-vw(15, 390);
  text-transform: capitalize;



  .divider {
    width: 100%;
    hr {
      width: 100%;
      height: 0;
      border: 0;
      border-bottom: px-to-vw(2, 390) solid $primary;
      margin: 0;
    }
  }

  .choices_and_prediction {
    display: block;
    grid-template-columns: repeat(2, 1fr);

    .choices {
      display: flex;
      flex-direction: column;
      gap: px-to-vw(8, 390);
      padding: px-to-vw(15, 390) 0;
      height: max-content;

      .choice_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: px-to-vw(12, 390);

        &.without_bets {
          grid-template-columns: 3fr 1fr;

          .choice {
            width: stretch;
            text-align: right;
          }
        }


        .type,
        .choice,
        .points,
        .value {
          font-size: px-to-vw(12, 390);
          font-weight: 300;
          line-height: px-to-vw(18, 390);
          color: $white;
            text-transform: capitalize;
          
        }

        .choice,
        .value {
          text-align: right;
          &.win {
            font-weight: 500;
            color: $secondary;
          }
        }
      }
    }

    .live_game {
      position: relative;
      display: flex;
      align-content: center;
      font-size: 2.791vw;
      font-weight: 700;
      line-height: 3.256vw;
      color: $black;
      text-transform: uppercase;
      padding: 2.674vw;
      white-space: no-wrap;
      padding: 2.674vw 5.581vw;

      border: 0.349vw solid rgba($color: $white, $alpha: 0.5);
      background: rgba($color: $white, $alpha: 0.3);
    }

    .score_card {
      display: block;
      height: 100%;

      & > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &:not(:first-child){
          margin-top: px-to-vw(10, 390);
        }
        &:last-child{
          margin-top: px-to-vw(8, 390);
        }
      }

      .title {
        font-size: px-to-vw(12, 390);
        font-weight: 300;
        line-height: px-to-vw(18, 390);
        text-transform: initial;
        color: $white;
        margin: 0;
      }

      .score {
        font-size: px-to-vw(12, 390);
        font-weight: 300;
        line-height: px-to-vw(18, 390);
        text-transform: initial;
        color: $white;
        margin: 0;
      }

      .points {
        font-size: px-to-vw(12, 390);
        font-weight: 300;
        line-height: px-to-vw(18, 390);
        text-transform: initial;
        color: $white;
        margin: 0;
        text-transform: capitalize;
      }
      .primary{
        .title,
        .points{
          color: $secondary;
          font-weight: 500;
        }
      }
    }

    .prediction {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .before_match {
    text-align: center;
    margin-top: px-to-vw(10, 390);
    p {
      font-size: px-to-vw(12, 390);
      font-style: normal;
      font-weight: 300;
      line-height: px-to-vw(30, 390);
      color: $white;
      margin: 0;
    }
  }
}
