// Typography
$rubik: "Rubik", sans-serif;
$work-sans: "Work Sans", sans-serif;
$roboto: "Roboto", sans-serif;


$black: #000;
$white: #fff;
$yellow: #C99A3D;

$background: #00120B;
$background-secondary: #031C13;
$primary: #00633A;
$secondary: $yellow; 

$border-color: $yellow;




$light-gray: #e5e5e5;
$medium-gray: #5e5e5e;
$green: #3fe193;
$red: #f50000;

// Colors
$sw-white: #fff;
$sw-black: #000000;
$sw-lime: #d1f669;
$sw-red: #ce2b79;
$sw-red2: #e30511;
$sw-red-dark: #8b1739;
$sw-blue-medium: #014a58;
$sw-blue-dark: #011d33;

$primary-color: $sw-lime;
