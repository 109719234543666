@import "../../styles/base.scss";

.select_container {
  border: px-to-vw(1, 390) solid $border-color;
  border-radius: px-to-vw(5, 390);
  background: $primary;
  margin: px-to-vw(15, 390) 0;

  .selected_value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: px-to-vw(40, 390);
    color: $white;

    .text {
      padding: px-to-vw(12, 390) px-to-vw(16, 390);
      font-size: px-to-vw(14, 390);
      text-transform: uppercase;
      p{
        margin: 0;
      }
    }

    .icon {
      height: 100%;
      aspect-ratio: 1/1;
      border-radius: px-to-vw(5, 390);

      .image {
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          margin-bottom: px-to-vw(3, 390);
        }
      }
      &.active {
        .image {
          rotate: 180deg;
          & > div {
            margin-bottom: px-to-vw(-3, 390);
          }
        }
      }
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    transition: all 0.5s;
    transform-origin: top;
    max-height: 0;
    overflow: scroll;
    padding: 0 px-to-vw(16, 390);
    margin: 0;
    list-style-type: none;

    &.active {
      max-height: px-to-vw(200, 390);
    }

    .option {
      color: $white;
      padding: px-to-vw(12, 390) 0;
      font-size: px-to-vw(14, 390);
      text-transform: uppercase;
      border-top: px-to-vw(2, 390) solid $white;
    }
  }
}
