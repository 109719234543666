@import "../../styles/base.scss";

.userProfile {
  background: $primary;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: px-to-vw(15, 390);
  margin: px-to-vw(15, 390) 0;
  border-radius: px-to-vw(5, 390);
  border: px-to-vw(1, 390) solid $primary;
  overflow: hidden;

  .data {
    display: flex;
    align-items: center;
    gap: px-to-vw(12, 390);
    padding: px-to-vw(6, 390) px-to-vw(24, 390);

    .image {
      display: block;
      width: px-to-vw(40, 390);
      height: px-to-vw(40, 390);

      img {
        width: px-to-vw(40, 390);
        height: px-to-vw(40, 390);
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
      }
    }

    .nickname {
      font-family: $roboto;
      font-size: px-to-vw(16, 390);
      font-weight: 400;
      line-height: px-to-vw(18, 390);
      color: $white;
    }
  }

  .ranking {
    display: flex;
    align-items: stretch;

    .points {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: px-to-vw(2, 390);
      color: $white;
      line-height: 1;
      white-space: nowrap;
      padding: px-to-vw(12, 390);

      span {
        font-size: px-to-vw(14, 390);
        line-height: px-to-vw(22, 390);
        font-weight: 400;
        &.value{
          font-size: px-to-vw(16, 390);
          line-height: px-to-vw(22, 390);
          font-weight: 700;
        }
      }
    }

    .rank {
      display: flex;
      align-items: center;
      justify-content: center;
      width: px-to-vw(58, 390);
      height: px-to-vw(58, 390);
      font-size: px-to-vw(14, 390);
      font-weight: 400;
      line-height: px-to-vw(22, 390);
      color: $black;
      white-space: nowrap;
      padding: px-to-vw(12, 390);
      border-radius: px-to-vw(5, 390);
      background: $yellow;
      .value {
        font-size: px-to-vw(16, 390);
      font-weight: 700;
      }
    }
  }
}
