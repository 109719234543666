@import "../../styles/base.scss";

.chat {
  background-color: transparent;

  .chat_container {
    background-color: transparent;
    * {
      background-color: transparent;
    }
    .message_list {
      padding: 2.326vw 0;
      .message {
        max-width: 100%;
        margin: px-to-vw(15, 390) 0;
        &.with-image {
          .cs-message__content-wrapper {
            background-color: transparent;
            padding: 0;
            margin: 1.163vw 0;
            .cs-message__content {
              padding: 0;
              background: none !important;
            }
          }
          &.cs-message--incoming {
            .message-with-image {
              img {
                object-position: left;
              }
            }
          }
        }
        &.no-avatar {
          .cs-message__content-wrapper {
            margin: 0 9.302vw;
          }
        }
        .cs-message__content-wrapper {
          border-radius: px-to-vw(16, 390);
          background-color: $background-secondary;
          padding: px-to-vw(18, 390);
          border: px-to-vw(1, 390) solid $primary;
          display: flex;
          flex-direction: row;
          min-width: 60%;
          max-width: 100%;
          .cs-message__content {
            padding: 0;
            position: relative;
            width: 100%;
            padding-right: 10.698vw;
            background: none;
            .cs-message__custom-content,
            .cs-message__html-content {
              font-family: $work-sans;
              font-size: 3.256vw;
              font-style: normal;
              font-weight: 400;
              line-height: 4.186vw;
              color: $white;
            }
            .user_name {
              font-family: $work-sans;
              font-size: 3.256vw;
              font-style: normal;
              font-weight: 600;
              line-height: 4.186vw;
              color: $white
            }
            .sent_time {
              position: absolute;
              bottom: -1.5vw;
              right: -1vw;
              font-family: $work-sans;
              font-size: 2.558vw;
              font-style: normal;
              font-weight: 400;
              line-height: 3.256vw;
              color: $white
            }
          }
        }
        .cs-message__avatar {
          width: 7.442vw;
          height: 7.442vw;
          .cs-avatar {
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 0;
              object-position: center;
            }
          }
        }
      }
      .separator {
        margin: px-to-vw(24, 390) 0;
        .separator-content {
          border-radius: px-to-vw(5, 390);
          background: $primary;
          padding: 1.86vw;
          font-family: $work-sans;
          font-size: px-to-vw(12, 390);
          font-style: normal;
          font-weight: 600;
          line-height: 3.721vw;
          color: $black;
          text-transform: capitalize;
        }

        &::before,
        &::after {
          margin: 0;
          background-color: rgba($white, $alpha: 0.2);
        }
      }
    }
    .input_container {
      position: relative;
      display: flex;
      align-items: center;
      .attach_button {
        position: absolute;
        top: 50%;
        left: 1.86vw;
        transform: translate(0, -50%);
        width: 7.442vw;
        height: 7.442vw;
        transition: all 0.5s;
        z-index: 50;
        &:hover {
          opacity: 0.8;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .send_button {
        width: 9.442vw;
        height: 9.442vw;
        transition: all 0.5s;
        padding: 1vw;
        &:hover {
          opacity: 0.8;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .cs-message-input {
        flex: 2;
        padding: 0;
        border: none;
        .cs-message-input__content-editor-wrapper {
          background-color: $white;
          padding: 2.721vw 5vw;
          margin: 0;
          .cs-message-input__content-editor {
            color: $black;
            padding: 0;
            font-family: $work-sans;
            font-size: 3.256vw;
            font-style: normal;
            font-weight: 400;
            min-height: 4.186vw;
            &::before {
              color: $white;
              padding: 0;
              font-family: $work-sans;
              font-size: 3.256vw;
              font-style: normal;
              font-weight: 400;
              line-height: 4.186vw;
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
  .photo_input {
    display: none;
  }
  .message-with-image {
    width: 100%;
    height: 23.256vw;

    &.incoming {
      img {
        object-position: left;
      }
    }
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: right;
    }
  }
  .ps__rail-y,
  .ps__rail-x {
    display: none;
  }
  .cs-avatar,
  .cs-avatar--md {
    border-radius: 7.442vw;
    overflow: hidden;
    width: 7.442vw !important;
    height: 7.442vw !important;
    min-width: 7.442vw !important;
    min-height: unset;
    
    img {
      width: 7.442vw !important;
      height: 7.442vw !important;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 0;
    }
  }
}
